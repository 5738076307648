<template>
    <div>
        <div class="top-color-ground">
        <nav class="navbar navbar-expand-lg">
            <div class="container-fluid">
                <a class="navbar-brand" href="#">
                    <img src="../../assets/logo_web.png" alt="" width="200" class="d-inline-block align-text-top">
                </a>
                <div class="collapse navbar-collapse">
                    <ul class="navbar-nav me-auto mb-2 mb-lg-0">
                    </ul>
                    <div class="d-flex" role="search">
                       <button class="btn btn-default" @click="direct()">
                            <font-awesome-icon :icon="['fas', 'headset']" class="top-color mr-2" size="sm"/>
                            <span class="fontW top-color">SUPPORT</span>
                       </button>
                       <button class="btn btn-dark" @click="signInOpen()">
                         <span class="fontW text-white">SIGN IN</span>
                       </button>
                    </div>
                </div>
            </div>
        </nav>
        <signinform ref="sModal"></signinform>
        <div class="container">
            <div class="row" style="margin-top:100px;">
                <div class="col-4">
                    <div class="card cds">
                        <div class="card-body p-0">
                            <div class="calen">
                                <div class="row">
                                    <div class="col-12 text-center fs-1 text-white" style="padding-top:50px !important;padding-bottom: 50px !important;">
                                        REQUEST DELETE ACCOUNT
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                
            </div>
        </div>
    </div>
    <div class="container pt-5">
        <form class="g-3 w-50" style="margin:auto;">
            <div class="mb-3">
                <label for="exampleFormControlInput1" class="form-label" style="font-weight:bold;">Email address</label>
                <input type="email" class="form-control" id="exampleFormControlInput1" placeholder="name@example.com" required>
            </div>
            <div class="mb-3">
                <label for="exampleFormControlInput1" class="form-label" style="font-weight:bold;">Phone number</label>
                <input type="phone" class="form-control" id="exampleFormControlInput1" placeholder="+6691234567" required>
            </div>
            <div class="mb-3">
                <label for="exampleFormControlTextarea1" class="form-label" style="font-weight:bold;">Detail</label>
                <textarea class="form-control" id="exampleFormControlTextarea1" rows="3" required></textarea>
            </div> 
            <div class="mb-3 text-center pt-5 pb-4">
                <button class="btn btn-danger" type="submit" @click="subop()">Submit</button>
                <button class="btn btn-default">Cancel</button>
            </div>
        </form>
    </div>
    </div>
</template>
<script>
export default{
 methods:{
    subop(){
        alert('Upon confirming the deletion request system will send notification Confirm deletion via email for information security');
        alert('Thank you.')
    }
 }
}
</script>
<style scoped>
.cds{
    background-color:#336998;
    border-bottom-left-radius: 20px;
    border-bottom-right-radius: 20px;
}
 .f-small{
  font-size: 14px;
 }
 .fontW{
    font-weight: normal;
    margin-left: 7px;
    font-size: 12px;
 }
 .top-color{
    color: #FFCF5E;
 }
 .top-color-ground{
    background: #005C8D;
    position: relative;
    height: 500px;
    display: block;
 }
</style>